<template>
    <div v-if="userId" class="card p-2 card-stretch">
        <div class="card-container">
            <h5 class="card-title" style="background-color: #101924; color: white; border-radius: 10px 10px 0 0px; padding: 10px;">{{ userProfile.user.firstName + ' ' + userProfile.user.lastName }}</h5>
            <a-tabs>
                <router-link :to="{ name: 'employees' }" class="btn btn-dark btn-sm float-right" slot="tabBarExtraContent"><em class="icon ni ni-back-ios mr-1"></em> Back to Employees</router-link>
                <a-tab-pane key="1" tab="Profile">
                    <div v-if="userProfile && employeeJobDetails" class="row">
                        <div class="col-12">
                            <div class="card ">
                                <div class="card-inner">
                                    <div class="row">
                                        <div class="col-12">
                                            <button v-on:click="updateUserSettings()" class="btn btn-success btn-sm float-right"><em class="icon ni ni-save-fill mr-2"></em>Save</button>
                                            <h5>Personal Details</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label class="form-label float-right">First Name</label>
                                        </div>
                                        <div class="col-4">
                                            <a-input class="invisibox" v-model="userProfile.user.firstName" placeholder="John Smith" readonly />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label class="form-label float-right">Last Name</label>
                                        </div>
                                        <div class="col-4">
                                            <a-input class="invisibox" v-model="userProfile.user.lastName" placeholder="John Smith" readonly />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label class="form-label float-right">Email Address</label>
                                        </div>
                                        <div class="col-4">
                                            <a-input class="invisibox" v-model="userProfile.user.email" placeholder="John Smith" readonly />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label class="form-label float-right">Start Date</label>
                                        </div>
                                        <div class="col-4">
                                            <a-date-picker class="invisibox" format="DD/MM/yyyy" v-model="employeeJobDetails.startDate" :class="{ 'is-error': $v.employeeJobDetails.startDate.$invalid }" placeholder="Start Date" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label class="form-label float-right">Vacated Date</label>
                                        </div>
                                        <div class="col-4">
                                            <a-date-picker class="invisibox" format="DD/MM/yyyy" v-model="employeeJobDetails.vacatedDate" placeholder="Vacated Date" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label class="form-label float-right">Gender</label>
                                        </div>
                                        <div class="col-4">
                                            <a-select class="invisibox" style="width: 100%;" v-model="employeeDetails.genderId">
                                                <a-select-option v-for="g in genders" :key="g.id">
                                                    {{ g.text }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label class="form-label float-right">NI / SSN Number</label>
                                        </div>
                                        <div class="col-4">
                                            <a-input class="invisibox" v-model="employeeJobDetails.niNumberOrSSN" placeholder="NI / SSN Number" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label class="form-label float-right">Date Of Birth</label>
                                        </div>
                                        <div class="col-4">
                                            <a-date-picker class="invisibox" v-model="employeeDetails.dateOfBirth" placeholder="Date Of Birth" format="DD/MM/YYYY" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label class="form-label float-right">Driving License Number</label>
                                        </div>
                                        <div class="col-4">
                                            <a-input class="invisibox" v-model="employeeDetails.drivingLicenseNumber" placeholder="Driving License Number" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label class="form-label float-right">Phone Number</label>
                                        </div>
                                        <div class="col-4">
                                            <a-input class="invisibox" v-model="employeeDetails.telephoneNumber" placeholder="Phone Number" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label class="form-label float-right">Address Line One</label>
                                        </div>
                                        <div class="col-4">
                                            <a-input class="invisibox" v-model="employeeDetails.addressLineOne" placeholder="Address Line One" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label class="form-label float-right">Address Line Two</label>
                                        </div>
                                        <div class="col-4">
                                            <a-input class="invisibox" v-model="employeeDetails.addressLineTwo" placeholder="Address Line Two" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label class="form-label float-right">Town Or City</label>
                                        </div>
                                        <div class="col-4">
                                            <a-input class="invisibox" v-model="employeeDetails.townOrCity" placeholder="Town Or City" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label class="form-label float-right">Post Code</label>
                                        </div>
                                        <div class="col-4">
                                            <a-input class="invisibox" v-model="employeeDetails.postCode" placeholder="Post Code" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label class="form-label float-right">County Or State</label>
                                        </div>
                                        <div class="col-4">
                                            <a-input class="invisibox" v-model="employeeDetails.countyOrState" placeholder="County Or State" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5">
                                            <label class="form-label float-right">Country</label>
                                        </div>
                                        <div class="col-4">
                                            <a-input class="invisibox" v-model="employeeDetails.country" placeholder="Country" />
                                        </div>
                                    </div>

                                    <button v-on:click="updateUserSettings()" class="btn btn-success btn-sm float-right"><em class="icon ni ni-save-fill mr-2"></em>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="Holidays">
                    <div class="row">
                        <div class="col-12">
                            <div v-if="userId">
                                <a-select class="float-right" v-model="startYear" @change="changeYear()" default-value="2022" style="width: 120px; position: absolute; right: 25px; top:10px; z-index:1;">
                                    <a-select-option value="2025">
                                        2025
                                    </a-select-option>
                                    <a-select-option value="2024">
                                        2024
                                    </a-select-option>
                                    <a-select-option value="2023">
                                        2023
                                    </a-select-option>
                                    <a-select-option value="2022">
                                        2022
                                    </a-select-option>
                                    <a-select-option value="2021">
                                        2021
                                    </a-select-option>
                                </a-select>
                                <holidaysummarytable :key="summaryKey" :changedYear="changedYear" :startYear="startYear" :userId="userId"></holidaysummarytable>
                                <hr class="preview-hr" />
                                <userholidays @updated="reloadSummaryTable()" :changedYear="changedYear" :startYear="startYear" :userId="userId"></userholidays>
                                <hr class="preview-hr" />
                                <sickAbsences @updated="reloadSummaryTable()" :changedYear="changedYear" :startYear="startYear" :userId="userId"></sickAbsences>
                                <hr class="preview-hr" />
                                <timeInLeui :userId="userId"></timeInLeui>
                            </div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="3" tab="Notes" force-render>
                    <div class="row g-gs">
                        <div class="col-lg-12">
                            <div class="card   h-100">
                                <div class="card-inner">
                                    <div v-if="userId">
                                        <notes :id="userId" :entityName="'user'" :getEndpoint="'/people/Get_Employee_Notes/' + userId" :saveEndpoint="'/people/Save_Employee_Note/'" :deleteEndpoint="'/people/Delete_Employee_Note/'"></notes>
                                    </div>
                                    <div v-else>
                                        <p class="text-center">Please save the account before adding notes.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="4" tab="Job Details">
                    <jobDetails v-if="userId" :userId="userId"></jobDetails>
                </a-tab-pane>
                <a-tab-pane key="5" tab="Holiday Settings">
                    <employeeannualleave :userId="userId" />
                </a-tab-pane>
                <a-tab-pane key="7" tab="Attachments">
                    <attachments :id="userId" :entityName="'employee'" :getEndpoint="'/people/Get_Employee_Attachments/' + userId" />
                </a-tab-pane>
                <a-tab-pane key="6" tab="Assets">
                    <employeeAssets :userId="userId" />
                </a-tab-pane>
                <!-- <a-tab-pane key="4" tab="Platform Settings">
        <p>User Settings To go here</p>
      </a-tab-pane> -->
            </a-tabs>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import userholidays from '@/components/holiday/userholidays'
import sickAbsences from '@/components/holiday/sickAbsences'
import employeeannualleave from '@/components/people/employees/employeeannualleave.vue'
import holidaysummarytable from '@/components/holiday/holidaysummarytable'
import jobDetails from '@/components/people/employees/employeejobdetails'
import timeInLeui from '@/components/holiday/timeinLeui.vue'
import notes from '@/components/generic/notes'
import employeeAssets from '@/components/people/employees/employeeassets.vue'
import attachments from '@/components/people/employees/employeeAttachments.vue'

export default {
    validations: {
        employeeJobDetails: {
            startDate: {
                required
            }
        }
    },
    components: { employeeannualleave, userholidays, holidaysummarytable, jobDetails, timeInLeui, sickAbsences, notes, employeeAssets, attachments },
    data() {
        return {
            userId: null,
            message: 'ready',
            userProfile: null,
            employeeDetails: null,
            genders: null,
            summaryKey: null,
            employeeJobDetails: {},
            startYear: '2024',
            changedYear: false
        }
    },
    created() {
        this.getView()
        if (this.$route.query.forceReload != null) {
            this.$router.push(this.$route.path)
            this.$router.go()
        }
        if (this.$route.query.userId != null) {
            this.userId = this.$route.query.userId
            this.get_EmployeeProfile(this.$route.query.userId)
            this.get_EmployeeDetails(this.$route.query.userId)
            this.getEmployeeJobDetails(this.$route.query.userId)
            this.getLists()
        } else {
            this.get_EmployeeProfile()
            this.getEmployeeJobDetails()
            this.getLists()
        }
    },
    methods: {
        getView() {
            this.$http
                .get('/ViewAccess/employee')
                .then(() => {})
                .catch(() => {})
        },
        changeYear() {
            if (this.changedYear === true) {
                this.changedYear = false
            } else {
                this.changedYear = true
            }
        },
        getEmployeeJobDetails(userId) {
            this.$http
                .get('/users/get_EmployeeJobDetails/' + userId)
                .then(response => {
                    this.employeeJobDetails = response.data
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
        },
        reloadSummaryTable() {
            this.summaryKey += 1
            this.changeYear()
        },
        updateUserSettings() {
            this.userProfile.user.userId = this.$route.query.userId
            this.$http
                .post('/users/Update_UserSettings/', this.userProfile.user)
                .then(() => {
                    this.$message.success('Details Updated')
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            this.$http
                .post('/users/Update_Employee_Details/', this.employeeDetails)
                .then(() => {
                    // this.$message.success('Details Updated')
                    this.get_EmployeeDetails(this.employeeDetails.userId)
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            this.$http
                .post('/users/Update_Employee_Job_Details/', this.employeeJobDetails)
                .then(() => {
                    // this.$message.success('Details Updated')
                    this.get_EmployeeDetails(this.employeeDetails.userId)
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
        },
        get_EmployeeProfile(userId) {
            this.$http
                .get('/users/Get_EmployeeProfile/' + userId)
                .then(response => {
                    this.userProfile = response.data
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
        },
        get_EmployeeDetails(id) {
            this.$http
                .get('/users/Get_EmployeeDetails/' + id)
                .then(response => {
                    this.employeeDetails = response.data
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
        },
        getLists() {
            this.$http
                .get('/lists/Get_List/genders')
                .then(response => {
                    this.genders = response.data
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
        }
    }
}
</script>

<style scoped>
.form-label {
    margin-top: 6px;
}
</style>
