var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card-inner"},[(_vm.employeeAssets)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.employeeAssets,"styleClass":"vgt-table condensed","search-options":{ 
                        enabled: true, placeholder: 'Search Employees'},"sort-options":{ enabled: true,},"pagination-options":{
                        enabled: true,
                        mode: 'records',
                        perPage: 50,
                        position: 'bottom',
                        perPageDropdown: [50,100,150],
                        dropdownAllowAll: false,
                        setCurrentPage: 1,
                        nextLabel: 'Next',
                        prevLabel: 'Prev',
                        rowsPerPageLabel: 'Rows per page',
                        ofLabel: 'of',
                        pageLabel: 'page', // for 'pages' mode
                        allLabel: 'All',
                      }},on:{"on-cell-click":_vm.showAsset},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'createdDate')?_c('div',[_vm._v(" "+_vm._s(_vm.$moment(props.row.createdDate).format('H:mm A LL'))+" ")]):(props.column.field == 'actions')?_c('div',[_c('a-dropdown',[_c('a',[_c('em',{staticClass:"icon ni ni-more-h",staticStyle:{"fontSize":"20px"}})]),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{staticStyle:{"color":"black"},on:{"click":function($event){return _vm.showAsset(props)}}},[_c('em',{staticClass:"icon ni ni-edit"}),_vm._v(" Edit")]),_c('a-menu-item',{staticStyle:{"color":"red"},on:{"click":function($event){return _vm.deleteAsset(props)}}},[_c('em',{staticClass:"icon ni ni-cross-circle"}),_vm._v(" Delete")])],1)],1)],1):_vm._e()]}}],null,false,3412248256)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }