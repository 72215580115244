<template>
  <div v-if="holidaySummary">
      <div class="row p-2">
            <div class="col-12">
                <h6 class="title" style="padding-bottom: 10px;">User Holiday Summary</h6>
                <table class="table striped">
                    <thead>
                        <th>Total Entitlement</th>
                        <th v-if="holidaySummary.entitlementIncludesPublicHolidays" style="width:13%;">Holidays Taken inc Bank Holidays</th>
                        <th v-else>Holidays Taken</th>
                        <th>Bank Holidays Taken</th>
                        <th>Holidays Scheduled</th>
                        <th>Holidays Pending Approval</th>
                        <th>Bank Holidays Remaining</th>
                        <th>Total Sick Days</th>
                        <th>Remaining</th>
                        <th>Total Accured</th>
                        <th>Total Left</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{holidaySummary.entitlement}}</td>
                            <td>{{holidaySummary.holidaysTaken}}</td>
                            <td>{{holidaySummary.bankHolidaysTaken}}</td>
                            <td>{{holidaySummary.holidaysScheduled}}</td>
                            <td>{{holidaySummary.holidaysUnapproved}}</td>
                            <td>{{holidaySummary.bankHolidaysRemaining}}</td>
                            <td>{{holidaySummary.totalSickDays}}</td>
                            <td>{{holidaySummary.remainingHoliday}}</td>
                            <td>{{holidaySummary.totalAccruedDays}}</td>
                            <td>{{holidaySummary.totalRemaining}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    props: {
        userId: {
            type: String,
        },
        startYear: {
            type: String,
        },
        changedYear: {
            type: Boolean,
        }
    },
    created (){
        this.get_User_HolidaySummary()
    },
    watch: {
        changedYear() {
            this.get_User_HolidaySummary()
        }
    },
    methods: {
        get_User_HolidaySummary () {
            let searchFilters  =  {userId: this.userId, startYear: this.startYear}
            this.$http.post('/people/Get_User_HolidaySummary/',searchFilters)
                .then((response) => {
                    this.holidaySummary = response.data
                    this.holidaySummary.totalRemaining = this.holidaySummary.totalAccrued - (this.holidaySummary.holidaysTakenDuration + this.holidaySummary.holidaysScheduledDuration) + ' ' + this.holidaySummary.durationMeasurement
                })
                .catch(() => {
                this.$message.error('There has been an error')
                })
        },
    },
    data () {
        return {
          holidaySummary: null,
        }
    }
}
</script>

<style>

</style>