<template>
<div>
  <div v-if="holiday && holidayEntitlement && calculated" style="padding:20px;">
    <a-form-model :model="holiday">
        <div class="row align-center">
            <div class="col-3">
                <div class="form-group">
                    <label  for="companyReg" class="form-label float-right">
                        <em class="icon ni ni-flag"></em> 
                        Reason
                    </label>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <div class="form-control-wrap">
                       <a-select class="invisibox" :class="{'is-error' : $v.holiday.absenceTypeId.$invalid }" v-model="holiday.absenceTypeId" :default-value="absenceTypes[0]">
                            <a-select-option v-for="absenceType in absenceTypes" v-bind:key="absenceType.id">
                                {{ absenceType.text }}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row  align-center">
            <div class="col-3">
                <div class="form-group">
                    <label  for="companyReg" class="form-label float-right">
                        <em class="icon ni ni-calendar"></em> 
                        Start Of Leave
                    </label>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <div class="form-control-wrap">
                        <a-date-picker v-if="holiday.durationMeasurementId === 1" :disabled-date="disableDates" v-model="holiday.startDate" @change="calculateLeave(), setEndDate()" format="DD-MM-YYYY" placeholder="Start" @openChange="handleStartOpenChange"/>
                        <a-date-picker v-else v-model="holiday.startDate" @change="calculateLeave(), setEndDate()" :disabled-date="disableDates" show-time format="DD-MM-YYYY HH:mm" placeholder="Start" @openChange="handleStartOpenChange"/>
                    </div>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="form-group">
                    <label  for="companyReg" class="form-label">
                        <em class="icon ni ni-calendar"></em> 
                        Start Is Half Day
                    </label>
                    <a-checkbox style="padding-left:10px;" v-model="holiday.startIsHalfDay" @change="calculateLeave()"/>
                </div>
            </div>
        </div>
        <div class="row  align-center">
            <div class="col-3">
                <div class="form-group">
                    <label  for="companyReg" class="form-label float-right">
                        <em class="icon ni ni-calendar"></em> 
                        End Of Leave
                    </label>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <div class="form-control-wrap">
                        <a-date-picker v-if="holiday.durationMeasurementId === 1" :disabled-date="disabledEndDate" v-model="holiday.endDate" @change="calculateLeave()" format="DD-MM-YYYY" placeholder="End" @openChange="handleStartOpenChange"/>
                        <a-date-picker v-else v-model="holiday.endDate" @change="calculateLeave()" :disabled-date="disabledEndDate" show-time format="DD-MM-YYYY HH:mm" placeholder="End" @openChange="handleStartOpenChange"/>
                    </div>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="form-group">
                    <label  for="companyReg" class="form-label">
                        <em class="icon ni ni-calendar"></em> 
                        End Is Half Day
                    </label>
                    <a-checkbox style="padding-left:17px;" v-model="holiday.endIsHalfDay" @change="calculateLeave()"/>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card bg-light">
                    <div class="card-header"><div style="padding-top:5px; width:200px; float:left;">Holiday Calculation </div>
                        <div class="float-right">
                            <a-select :disabled="true" style="width:100px;" class="mr-3"  :v-model="holiday.durationMeasurementId" :default-value="holidayEntitlement.employeeAnnualLeaveAllowance.durationMeasurementId" >
                                <a-select-option v-for="durationMeasurement in durationMeasurements" v-bind:key="durationMeasurement.id">
                                    {{ durationMeasurement.text }}
                                </a-select-option>
                            </a-select>
                             <a-input-number @change="reCalculateLeave()" id="inputNumber" disabled v-model="holidayDaysUsed" :min="0" />
                        </div>
                    </div>
                    <div class="card-inner">
                        <h5 class="card-title">This holiday will result in</h5>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class="text-center">Before</th>
                                    <th class="text-center">After</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Entitlement:</td>
                                    <td class="text-center">{{holidayEntitlement.holidayEntitlementCounts.entitlementCount}}</td>
                                    <td class="text-center">{{holidayEntitlement.holidayEntitlementCounts.entitlementCount}}</td>
                                </tr>
                                <tr>
                                    <td>Scheduled/Approved:</td>
                                    <td class="text-center">{{holidayEntitlement.holidayEntitlementCounts.approvedCount}}</td>
                                    <td class="text-center">{{calculated.approvedCount}}</td>
                                </tr>
                                <tr>
                                    <td>Scheduled/Awaiting Approval:</td>
                                    <td class="text-center">{{holidayEntitlement.holidayEntitlementCounts.unApprovedCount}}</td>
                                    <td class="text-center">{{calculated.unApprovedCount}}</td>
                                </tr>
                                <tr>
                                    <td>Holiday Taken:</td>
                                    <td class="text-center">{{holidayEntitlement.holidayEntitlementCounts.takenCount}}</td>
                                    <td class="text-center">{{holidayEntitlement.holidayEntitlementCounts.takenCount}}</td>
                                </tr>
                                <tr>
                                    <td>Remaining:</td>
                                    <td class="text-center">{{holidayEntitlement.holidayEntitlementCounts.remainingCount}}</td>
                                    <td class="text-center">{{calculated.remainingCount}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="card-text mt-2"><em class="icon ni ni-info"></em> Please note that this may not take into account any un-authorised or pending holidays</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row align-center">
            <div class="col-2">
                <div class="form-group">
                    <label  for="companyReg" class="form-label float-right">
                        <em class="icon ni ni-edit-alt"></em> 
                        Note
                    </label>
                </div>
            </div>
            <div class="col-lg-10">
                <div class="form-group">
                    <div class="form-control-wrap">
                        <a-input v-model="holiday.note" />
                    </div>
                </div>
            </div>
        </div>

        <a-form-model-item>
            <div>
                
            </div>
            <a-button v-if="!holiday.id" :disabled="!formComplete"  type="dark" class="float-right mt-3" @click="saveHolidayRequest">
                Send Request
            </a-button>
            <a-button v-if="holiday.id" :disabled="!formComplete"  type="dark" class="float-right mt-3" @click="updateHolidayRequest">
                <span v-if="userId">Update Request</span>
                <span v-if="!userId">Save Holiday</span>
            </a-button>
        </a-form-model-item>
    </a-form-model>
  </div>
  <div v-else style="padding: 20px;" class="text-center">
    Please set the employee annual leave settings before booking holiday.
    </div>
</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    props: {
        selectedHolidayId: {
            type: Number
        },
        userId: {
            type: String
        },
    },
    validations: {
        holiday: {
            absenceTypeId: {
                required
            },
            startDate: {
                required
            },
            endDate: {
                required
            }
        }
    },
    data() {
        return { 
            holidayRequirementAmount: 0,
            calculated: {entitlementCount: null, takenCount: null, approvedCount: null, unApprovedCount: null, remainingCount: null,   },
            holidayEntitlement: null,
            holiday: null,
            absenceTypes: [],
            holidayTypes: [],
            durationMeasurements: [],
            endOpen: false,
            holidayDaysUsed: null,
            disabledDates: []
        }
    },
    created () {
        this.getLists()
        if (this.selectedHolidayId == null) {
            this.getHoliday()
        } else {
            this.getHoliday(this.selectedHolidayId)
        }
    },
    computed: {
        formComplete () {
            if (this.holiday.absenceTypeId != null)
            {
                return true
            } else 
            return false
        }
    },
     watch: {
        selectedHolidayId() {
           this.getLists()
           if (this.selectedHolidayId == null) {
               this.getHoliday()
           } else {
               this.getHoliday(this.selectedHolidayId)
           }
        }
    },
    methods: {
        // This is function I am trying to call to disable this date as an example
        disableDates(current){
            if(this.disabledDates.includes(this.$moment(current).format('YYYY-MM-DD'))) {
                return true
            } else {
                return false
            }
        },
        // END
        setEndDate() {
            this.holiday.endDate = this.holiday.startDate
        },
        approveHoliday(){
            this.$http.post('/people/Approve_Absence/', this.holiday)
            .then(() => {
                this.$message.success('Holiday Approved')
                this.getHolidays()
                this.getEntitlementCounts()
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        denyHoliday(){
            this.$http.post('/people/deny_Absence/', this.holiday)
            .then(() => {
                this.$message.success('Holiday Denied')
                this.getHolidays()
                this.getEntitlementCounts()
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        reCalculateLeave () {
            if (this.holidayEntitlement) {
                this.calculated.approvedCount    = this.holidayEntitlement.holidayEntitlementCounts.approvedCount          
                this.calculated.unApprovedCount  = this.holidayEntitlement.holidayEntitlementCounts.unApprovedCount  + this.holidayDaysUsed       
                this.calculated.remainingCount   = this.holidayEntitlement.holidayEntitlementCounts.remainingCount - this.holidayDaysUsed
            }
        },
        calculateLeave() {
            this.holiday.userId = this.userId
            this.$http.post('/people/Recalculate_HolidayDecrement/', this.holiday)
            .then((response) => {
                this.holidayDaysUsed = response.data.holidayDaysUsed
                this.reCalculateLeave()
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        getEntitlementCounts () {
            this.$http.get('/people/Get_User_Holiday_Entitlement/' + this.userId)
            .then((response) => {
                this.holiday.durationMeasurementId = response.data.employeeAnnualLeaveAllowance.durationMeasurementId
                this.holidayEntitlement = response.data
                this.$nextTick(() => {
                        this.reCalculateLeave()
                })
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        setAllDayEvent () {
            this.holiday.endDate = new Date()
            this.holiday.startDate = new Date()
        },
        saveHolidayRequest() {
            if (this.userId != null) {
                this.holiday.userId = this.userId
            }
            if (this.$v.holiday.$invalid) {
                this.$message.error('Please check for any form errors.')
            } else {
                if (this.$store.state.userSettings.roles[0].id === '6BAB1BED-A580-4B53-8088-023E65D972C3') {
                    this.$http.post('/people/Book_Holiday', this.holiday)
                    .then(() => {
                        this.$emit('updated')
                        this.$message.success('Booked Holiday')
                        this.getEntitlementCounts()
                        this.holiday = {}
                    })
                    .catch(() => {
                    this.$message.error('There has been an error')
                    })
                } else {
                    this.$http.post('/people/Add_Holiday_Request', this.holiday)
                    .then(() => {
                        this.$emit('updated')
                        this.$message.success('Request Sent')
                        this.getEntitlementCounts()
                        this.holiday = {}
                    })
                    .catch(() => {
                    this.$message.error('There has been an error')
                    })
                }
            }
        },
        updateHolidayRequest() {
            this.$http.post('/people/Update_Holiday_Request', this.holiday)
            .then(() => {
                this.$emit('updated')
                this.$message.success('Request Updated')
                this.getEntitlementCounts()
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        getLists(){
            this.$http.get('/lists/Get_List/absenceTypes')
            .then((response) => {
                this.absenceTypes = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            }),
            this.$http.get('/people/Get_Disabled_Dates/' + this.userId)
            .then((response) => {
                this.disabledDates = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            }),
             this.$http.get('/lists/Get_List/durationMeasurements')
            .then((response) => {
                this.durationMeasurements = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        getHoliday(id){
            let uri  = '/people/Get_Holiday/'
            if (id != null){
                uri = uri + id
            }
            this.$http.get(uri)
            .then((response) => {
                this.holiday = response.data
                if (!this.holiday.id) {
                    this.setAllDayEvent()
                    this.holiday.duration = 1
                }
                this.getEntitlementCounts()
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        disabledStartDate(startDate) {
            const endDate = this.holiday.endDate
            if (!startDate || !endDate) {
                return false;
            }
            return startDate.valueOf() > endDate.valueOf();
            },
        disabledEndDate(endDate) {
            // const startDate = this.holiday.startDate
            const startDate = this.$moment(this.holiday.startDate).subtract('day', 1)
            if (!endDate || !startDate) {
                return false;
            }
            return startDate.valueOf() >= endDate.valueOf();
        },
        handleStartOpenChange(open) {
            if (!open) {
                this.endOpen = true;
            }
        },
        handleEndOpenChange(open) {
            this.endOpen = open;
        },
    },
   
}
</script>

<style scoped>
.form-label {
    margin-top: 8px
}
</style>