<template>
    <div class="card">
        <div class="card-inner">
            <div class="card-title">
                <button v-on:click="requestHoliday()" class="btn btn-dark btn-sm float-right">
                  <span v-if="!userId">Request A Holiday</span>
                  <span v-if="userId">Assign A Holiday</span>
                </button>
                <h6>Holidays</h6>
            </div>
            <div class="card-body">
        <vue-good-table v-if="holidays"
          :columns="columns"
          :rows="holidays"
          @on-cell-click="selectHoliday"
          styleClass="vgt-table condensed tblhov"
          :search-options="{ enabled: true, placeholder: 'Search Invoices'}"
          :sort-options="{ enabled: true, field: 'duration', type: 'asc'}"
        >
         <template slot="table-row" slot-scope="props" >
          <span class="vgt-muted"  v-if="props.column.field == 'total'" >
            {{props.formattedRow[props.column.field]}}
          </span>
            <template v-else-if="props.column.field == 'userName'">
              <avatar style="margin-left:0px;" :src="props.row.avatarURI" :size="30" :rounded="true" :backgroundColor="props.row.avatarColor" :username="props.row.userName"/>
          </template>
          <div v-else-if="props.column.field == 'duration'">
            <span>{{props.row.duration}} {{props.row.durationMeasurement}} starting on</span>
          </div>
          <div v-else-if="props.column.field == 'startDate'">
            <span><strong>{{ $moment(props.row.startDate).format("LL") }}</strong></span>
          </div>
          <div v-else-if="props.column.field == 'requestStatus'">
            <span><strong v-bind:class="{'text-success': props.row.requestStatus === 'Approved','text-danger': props.row.requestStatus === 'Declined', 'text-info': props.row.requestStatus === 'Pending' }">{{props.row.requestStatus}}</strong> </span>
          </div>
          <div v-else-if="props.column.field == 'action'">
            <a-dropdown>
              <a><em class="icon ni ni-more-h" style="fontSize: 20px"></em></a>
              <a-menu slot="overlay">
                <a-menu-item style="color: green;" v-if="props.row.requestStatus != 'Approved'" @click="approveHoliday(props)"><em class="icon ni ni-check"/> Approve</a-menu-item>
                <a-menu-item style="color: red;" v-if="props.row.requestStatus != 'Declined'" @click="denyHoliday(props)"><em class="icon ni ni-cross-circle"/> Deny</a-menu-item>
                <a-menu-item style="color: black;" v-if="props.row.requestStatus == 'Declined'" @click="clearHoliday(props)"><em class="icon ni ni-cross-circle"/> Clear</a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          <div v-else>
            {{props.formattedRow[props.column.field]}}
          </div>
         </template>
        </vue-good-table>
            </div>
        </div>
        <a-drawer title="Holiday / Leave Request" :width="'60%'" placement="right" :closable="true" @close="closeRequestHoliday()" :visible="showHoliday">
          <holiday @updated="holidayUpdated" :selectedHolidayId="selectedHolidayId" :userId="userId"></holiday>
        </a-drawer>
    </div>
</template>

<script>
import holiday from '@/components/holiday/holiday.vue'

export default {
  props: {
        userId: {
            type: String
        },
        startYear: {
            type: String,
        },
        changedYear: {
            type: Boolean,
        }
  },
  components: { holiday },
  data() {
    return {
        holidays: [],
        placement: 'bottomCenter',
        columns: [
            {
                label: 'Type',
                field: 'absenceType',
                sortable: false 
            },
            { label: 'Duration', field: 'duration', sortable: false },
            { label: 'Start Date', field: 'startDate' },
            { label: 'Status', field:'requestStatus', sortable: true},
            { label: 'Notes', field:'note', sortable: false },
            { label: '', field:'action', width:'10%', tdClass: 'text-center', thClass: 'text-center', sortable: false },
        ],
        showHoliday: false,
        selectedHolidayId: null,
    }
  },
  watch: {
        changedYear() {
            this.getHolidays()
        }
    },
  created() {
      this.getHolidays()

  },
  methods: {
    approveHoliday(props){
      this.$http.post('/people/Approve_Absence/', props.row)
      .then(() => {
        this.$message.success('Holiday Approved')
        this.getHolidays()
        this.$emit('updated')
      })
      .catch(() => {
      this.$message.error('There has been an error')
      })
    },
    denyHoliday(props){
      this.$http.post('/people/deny_Absence/', props.row)
      .then(() => {
        this.$message.success('Holiday Denied')
        this.getHolidays()
        this.$emit('updated')
      })
      .catch(() => {
      this.$message.error('There has been an error')
      })
    },
    clearHoliday(props){
      this.$http.post('/people/Clear_Absence/', props.row)
      .then(() => {
        this.$message.success('Holiday Cleared')
        this.getHolidays()
        this.$emit('updated')
      })
      .catch(() => {
      this.$message.error('There has been an error')
      })
    },
    holidayUpdated () {
      this.$emit('updated')
      this.getHolidays()
      this.showHoliday = false
      this.selectedHoliday = null
    },
    selectHoliday (row) {
      this.selectedHolidayId  = row.row.id
      this.showHoliday = true
    },
    requestHoliday() {
      this.showHoliday = true
    },
    getHolidays(){
      let searchFilter = { userId: this.userId, startYear: this.startYear}
       this.$http.post('/people/Get_User_Holidays/', searchFilter)
        .then((response) => {
            this.holidays = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
    },    
    closeRequestHoliday(){
      this.selectedHoliday = null
      this.showHoliday = false
    }
  },
}
</script>

<style>
.card-body {
    padding: 5px;
    padding-left: 0px;
}
</style>