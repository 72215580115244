<template>
    <div class="card">
        <div class="card-inner">
            <div class="card-title">
                <button v-on:click="requestTimeInLieu()" class="btn btn-dark btn-sm float-right">
                  <span v-if="!userId">Request Time In Lieu</span>
                  <span v-if="userId">Assign Time In Lieu</span>
                </button>
                <h6>Time In Lieu</h6>
            </div>
            <div class="card-body">
                <vue-good-table v-if="timeInLieu"
                :columns="columns"
                :rows="timeInLieu"
                styleClass="vgt-table condensed tblhov"
                :search-options="{ enabled: true, placeholder: 'Search Invoices'}"
                :sort-options="{ enabled: true,}"
                >
                <template slot="table-row" slot-scope="props" >
                    <template v-if="props.column.field == 'actions'">
                       <a-dropdown>
                            <a><em class="icon ni ni-more-h" style="fontSize: 20px;"></em></a>
                            <a-menu slot="overlay">
                                <a-menu-item style="color: red;" v-if="props.row.requestStatus != 'Declined'" @click="deleteTimeInLieu(props.row)"><em class="icon ni ni-cross-circle"/> Delete</a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </template>
                </template>
                </vue-good-table>
            </div>
        </div>
        <a-modal title="Time In Lieu Request" @close="closeRequestTimeInLieu()" v-model="showTimeInLieu">
          <div class="row">
              <div class="col-12">
                  <label class="form-label">Duration Measurement</label>
                  <a-select v-model="selectedTimeInLieu.durationMeasurementId" class="invisibox" style="width: 100%">
                      <a-select-option v-for="d in durationMeasurements" :key="d.id">
                          {{ d.text }}
                      </a-select-option>
                  </a-select>
              </div>
          </div>
          <div class="row">
              <div class="col-12">
                  <label class="form-label">Allocation Value</label>
                  <a-input class="invisibox" v-model="selectedTimeInLieu.allocationValue"/>
              </div>
          </div>
          <template slot="footer">
                  <a-button class="btn btn-success" @click="addTimeInLieu()"><em class="icon ni ni-save-fill mr-2"/> Save</a-button>
              </template>
        </a-modal>
    </div>
</template>

<script>

export default {
  props: {
        userId: {
            type: String
        },
  },
  data() {
    return {
        columns: [
            {
                label: 'Allocated Value',
                field: 'allocationValue',
            },
            { label: 'Duration Measurement', field: 'durationMeasurement' },
            {
                label: '',
                field: 'actions',
                width: '10%',
                tdClass: 'text-center', 
                thClass: 'text-center'
            },
        ],
        showTimeInLieu: false,
        timeInLieu: null,
        selectedTimeInLieu: {},
        durationMeasurements: null,
    }
  },
  created() {
      this.getTimeInLieu()
        this.getLists()
  },
  methods: {
    addTimeInLieu(){
        this.selectedTimeInLieu.userId = this.userId
        this.$http.post('/people/Add_TimeInLieu/', this.selectedTimeInLieu)
        .then(() => {
            this.$message.success('Time In Lieu Added')
            this.getTimeInLieu()
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
    },
    requestTimeInLieu() {
      this.showTimeInLieu = true
    },
    getTimeInLieu(){
       this.$http.get('/people/Get_TimeInLieu/'+ this.userId)
        .then((response) => {
            this.timeInLieu = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
    },    
    closeRequestTimeInLieu(){
      this.selectedTimeInLieu = null
      this.showTimeInLieu = false
    },
    getLists() {
        this.$http.get('/lists/Get_List/durationMeasurements')
        .then((response) => {
            this.durationMeasurements = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
    },
    deleteTimeInLieu(p) {
        this.$http.post('/people/Delete_TimeInLieu/', p)
        .then(() => {
            this.$message.success('Time In Lieu Deleted')
            this.getTimeInLieu()
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
    }
  },
}
</script>

<style>
.card-body {
    padding: 5px;
    padding-left: 0px;
}
</style>