<template>
  <div>
      <div class="card-inner">
          <vue-good-table v-if="employeeAssets"
                        :columns="columns"
                        :rows="employeeAssets"
                        @on-cell-click="showAsset"
                        styleClass="vgt-table condensed"
                        :search-options="{ 
                          enabled: true, placeholder: 'Search Employees'}"
                        :sort-options="{ enabled: true,}"
                        :pagination-options="{
                          enabled: true,
                          mode: 'records',
                          perPage: 50,
                          position: 'bottom',
                          perPageDropdown: [50,100,150],
                          dropdownAllowAll: false,
                          setCurrentPage: 1,
                          nextLabel: 'Next',
                          prevLabel: 'Prev',
                          rowsPerPageLabel: 'Rows per page',
                          ofLabel: 'of',
                          pageLabel: 'page', // for 'pages' mode
                          allLabel: 'All',
                        }">
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'createdDate'">
                            {{ $moment(props.row.createdDate).format('H:mm A LL') }}
                            </div>
                            <div v-else-if="props.column.field == 'actions'">
                            <a-dropdown>
                                <a><em class="icon ni ni-more-h" style="fontSize: 20px;"></em></a>
                                <a-menu slot="overlay">
                                <a-menu-item style="color: black;" @click="showAsset(props)"><em class="icon ni ni-edit"/> Edit</a-menu-item>
                                <a-menu-item style="color: red;" @click="deleteAsset(props)"><em class="icon ni ni-cross-circle"/> Delete</a-menu-item>
                                </a-menu>
                            </a-dropdown>
                            </div>
                        </template>
          </vue-good-table>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        userId: {
            type: String
        }
    },
    data() {
        return {
            employeeAssets: [],
            columns: [
                { label: 'Name', field: 'name' },
                { label: 'Type', field: 'assetTypeName' },
                { label: 'Created Date', field: 'createdDate' },
                { label: 'Created By User', field: 'createdByUser'},
                { label: 'Actions', field: 'actions', tdClass: 'text-center', thClass: 'text-center'}
                //{ label: 'Actions', field: 'actions', tdClass: 'text-center', thClass: 'text-center', width: 200 },
            ],
        }
    },
    created(){
        this.getEmployeeAssets(this.userId)
    },
    methods: {
        getEmployeeAssets (userId) {
            this.$http.get('/users/get_EmployeeAssets/' + userId)
            .then((response) => {
              this.employeeAssets = response.data
            })
            .catch(() => {
              this.$message.error('There has been an error')
            })
        },
        showAsset(params) {
            this.$router.push({name: 'asset', query: {id: params.row.id}})
        },
    }
}
</script>

<style>

</style>